import { Component, OnInit } from '@angular/core';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private theme: ThemeService) {
    document.getElementById('');
    // setTimeout(() => {}, 10);
  }

  ngOnInit() {
    this.theme.loadCurrentTheme();
  }
}
