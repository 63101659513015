<mat-drawer-container autosize>
  <mat-drawer #drawer mode="over" [opened]="isOpen">
    <ul class="list loading" *ngIf="loading">
      <li class="skeleton dark" *ngFor="let item of [1, 2, 3, 4, 5]">
        <button [disableRipple]="true" mat-button class="s">&rlm;</button>
      </li>
    </ul>

    <ul class="list" *ngIf="!loading">
      <li>
        <form [formGroup]="filter_form" class="card-input">
          <label for="sectors"> UTI </label>
          <div>
            <input
              type="text"
              id="sectors"
              placeholder="UTI"
              [matAutocomplete]="auto"
              formControlName="sector"
            />
          </div>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option (click)="filterSubmitHandler()" [value]="">
              Todos
            </mat-option>
            <mat-option
              *ngFor="
                let sector of sectorList
                  | filterData : filter_form.value.sector : 'name'
              "
              (click)="filterSubmitHandler(sector)"
              [value]="sector"
            >
              {{ sector['name'] }}
            </mat-option>
          </mat-autocomplete>
        </form>
      </li>

      <li class="logout">
        <button class="primary" title="Sair" mat-button (click)="logout()">
          <span class="one-line">Logout</span>
          <i icon="logout"></i>
        </button>
      </li>
    </ul>
  </mat-drawer>

  <mat-drawer-content>
    <nav>
      <div class="content">
        <button
          title="Encolher menu"
          class="btn-drawer"
          type="button"
          mat-icon-button
          (click)="drawerToggle()"
          [ngClass]="{ close: !drawer.opened }"
        >
          <i icon="menu"></i>
        </button>

        <a class="logo" routerLink="/">
          <img src="assets/images/logo.png" alt="logo" />
          <!-- <img src="assets/images/logo-branco_01.png" alt="logo" /> -->
          <!-- <img src="assets/images/Logo-HSF-01-color.png" alt="logo" /> -->
        </a>

        <a class="logo right" routerLink="/">
          <img src="assets/images/logo.png" alt="logo" />
          <!-- <img src="assets/images/logo-branco_01.png" alt="logo" /> -->
          <!-- <img src="assets/images/Logo-HSF-01-color.png" alt="logo" /> -->
        </a>

        <button
          title="expand"
          mat-icon-button
          aria-label=""
          (click)="handleToggleNavbar()"
          [matTooltip]="!isFullScreen ? 'Expandir' : 'Comprimir'"
        >
          <i *ngIf="!isFullScreen" icon="expand"></i>
          <i *ngIf="isFullScreen" icon="compress"></i>
        </button>
      </div>
    </nav>

    <router-outlet #outlet="outlet"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<div id="canvas"></div>
