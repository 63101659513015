import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INotification } from 'src/app/models/notification';
import { IPatient } from 'src/app/models/patient';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.scss'],
})
export class ModalNotificationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { patient: IPatient },
    private dialogRef: MatDialogRef<ModalNotificationComponent>,
    private patientService: PatientService
  ) {}

  loading = true;
  notifications: INotification[] = [];

  ngOnInit(): void {
    console.log(this.data);
    this.getPatientNotification();
  }

  getPatientNotification() {
    this.loading = true;
    this.patientService.getPatientNotification(this.data.patient).subscribe({
      next: (data) => {
        console.log(data);
        this.notifications = data.filter((el) => !el.read);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
