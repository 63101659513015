import { Injectable } from '@angular/core';

declare global {
  interface HTMLElement {
    mozRequestFullScreen?(): void;

    webkitRequestFullScreen?(): void;

    msRequestFullscreen?(): void;
  }

  interface Document {
    mozCancelFullScreen?(): void;

    webkitExitFullscreen?(): void;

    msExitFullscreen?(): void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ToggleFullScreenService {
  async toggleFullScreen(isFullScreen: boolean) {
    if (!isFullScreen) {
      const docElm: HTMLElement = document.documentElement;
      if (docElm.requestFullscreen) {
        await docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }

      return !isFullScreen;
    } else {
      const doc: Document = document;
      if (doc.exitFullscreen) {
        await doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }

      return !isFullScreen;
    }
  }

  requestFullScreen(element: any) {
    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    }
    // else if (typeof window.ActiveXObject !== "undefined") {
    //   // Older IE.
    //   const wscript = new ActiveXObject("WScript.Shell");
    //   if (wscript !== null) {
    //     wscript.SendKeys("{F11}");
    //   }
    // }
  }
}
