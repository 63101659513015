<section>
  <header>
    <b class="f-14">{{ data.patient.name }}</b>

    <span class="bed-container">
      <p class="f-14 lesions">{{ data.patient.number_of_ulcers }} Lesões</p>
      <span class="line"> | </span>
      <p class="f-12 bed">{{ data.patient.bed.name }}</p>
      <span class="line"> - </span>
      <p class="f-12 bed">{{ data.patient.bed.fk_sector_name }}</p>
    </span>
  </header>

  <page-loading *ngIf="loading"></page-loading>

  <div class="content" *ngIf="!loading">
    <div
      class="notification"
      *ngFor="let text of notifications; let idx = index"
    >
      <p class="f-14">
        {{ text.notification_text }}
      </p>
      <h5 class="f-10">
        {{ text.updated_at | date : 'dd-MMMM-yyyy HH:MM:ss' }}
      </h5>

      <!-- <div class="line" *ngIf="idx < notifications.length - 1"></div> -->
    </div>
  </div>
</section>
